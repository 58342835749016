type Config = {
  appName: string;
  appDomain: string;
  appOrigin: string;
};

const config: Config = {
  appName: 'Peka.Live',
  appDomain: 'peka.live',
  appOrigin: 'https://peka.live',
};

export default config;
