import {
  Component,
  For,
  JSXElement,
  Show,
  createEffect,
  createMemo,
  createResource,
  createSignal,
  onMount,
} from 'solid-js';
import {
  render,
} from 'solid-js/web';
import {
  List as IList,
} from 'immutable';
import {
  Router,
  Routes,
  useRoutingNavigate,
} from '../components/routing';
import smiles from '../smiles.json';
import config from '../config';

const Smile: Component<{
  code: string;
  onClick?: (code: string) => void;
}> = (props) => <span class={`smile smile_${props.code}`} title={`:${props.code}:`} onClick={props.onClick && (() => props.onClick!(props.code))}>{' '}</span>;

const Message: Component<{
  message: string;
  author?: string;
  isLast?: boolean;
}> = (props) => {
  let ref: HTMLDivElement;
  onMount(() => {
    if(props.isLast) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  });
  const content = createMemo<JSXElement>(() => {
    const p = props.message;
    let pieces: JSXElement[] = [];
    let line = '';
    const flush = () => {
      if(line.length > 0) {
        pieces.push(<span>{line}</span>);
        line = '';
      }
    };
    for(let i = 0; i < p.length; ) {
      if(p[i] == ':') {
        let j;
        for(j = i + 1; j < p.length && /[a-z0-9\-]/.test(p[j]); ++j);
        let added = false;
        if(j < p.length && p[j] == ':') {
          const code = p.substring(i + 1, j);
          if(code in smiles) {
            flush();
            pieces.push(<Smile code={code} />);
            added = true;
          }
        }
        if(!added) {
          line += p.substring(i, j + 1);
        }
        i = j + 1;
      }
      else {
        line += p[i++];
      }
    }
    flush();
    return pieces;
  });
  return (
    <div ref={ref!}>
      <div class="author">{props.author ?? 'system'}</div>
      <div class="content">{content()}</div>
    </div>
  );
};

const Stream: Component<{
  id: string;
  player: JSXElement;
}> = (props) => {

  return (
    <div class="stream">
      {props.player}
      <Chat id={props.id} />
    </div>
  );
};

const Chat: Component<{
  id: string;
}> = (props) => {
  const [messages, setMessages] = createSignal(IList<{
    message: string;
    author?: string;
  }>());
  let refInput: HTMLInputElement;

  const addMessage = (message: string, author?: string) => {
    setMessages((messages) => messages.withMutations((messages) => {
      messages.push({
        message,
        author,
      });
      while(messages.size > 100) {
        messages.shift();
      }
    }));
  };

  const token = window.localStorage.getItem('token');

  const [websocket, { mutate: setWebsocket, refetch: recreateWebsocket }] = createResource(() => new Promise<WebSocket>((resolve) => {
    addMessage('chat connecting...');
    const ws = new WebSocket(`wss://${config.appDomain}/worker/chat?${new URLSearchParams({
      chat: props.id,
      token: token ?? '',
    })}`);
    ws.addEventListener('open', (e) => {
      addMessage('chat connected');
      resolve(ws);
    });
    ws.addEventListener('message', (e) => {
      const [author, message] = JSON.parse(e.data);
      addMessage(message, author);
    });
    ws.addEventListener('close', (e) => {
      if(websocket() == ws) {
        addMessage('chat disconnected');
        setWebsocket(undefined);
        recreateWebsocket();
      }
    });
    ws.addEventListener('error', (e) => {
      addMessage('chat error');
      ws.close();
      setWebsocket(undefined);
      recreateWebsocket();
    });
  }));

  const [inputText, setInputText] = createSignal('');

  const onInput = () => {
    setInputText(refInput.value);
    setSmilePrefix((() => {
      if(refInput.selectionStart == null) return null;
      const s = inputText();
      const i = s.lastIndexOf(':', refInput.selectionStart);
      return i >= 0 ? s.substring(i + 1, refInput.selectionStart) : null;
    })());
  };

  const [smilePrefix, setSmilePrefix] = createSignal<string | null>(null);
  const smileCandidates = createMemo(() => {
    const prefix = smilePrefix();
    if(prefix == null) return [];
    const candidates: string[] = [];
    for(const smile of Object.keys(smiles)) {
      if(smile.startsWith(prefix)) {
        candidates.push(smile);
      }
    }
    return candidates;
  });

  const onKeyDown = (e: KeyboardEvent) => {
    switch(e.key) {
    case 'Enter':
      e.preventDefault();
      {
        const ws = websocket();
        if(ws) {
          ws.send(refInput.value);
          refInput.value = '';
          onInput();
        }
      }
      break;
    case 'Tab':
      e.preventDefault();
      {
        const prefix = smilePrefix();
        if(prefix) {
          const candidates = smileCandidates();
          if(candidates.length <= 0) break;
          let sharedPrefix = candidates[0];
          if(candidates.length == 1) {
            if(sharedPrefix in smiles) sharedPrefix += ': ';
          } else {
            for(let j = 1; j < candidates.length; ++j) {
              let k;
              for(k = 0; k < sharedPrefix.length && k < candidates[j].length && sharedPrefix[k] == candidates[j][k]; ++k);
              sharedPrefix = sharedPrefix.substring(0, k);
            }
          }
          refInput.setRangeText(sharedPrefix.substring(prefix.length), refInput.selectionStart!, refInput.selectionEnd!, 'end');
          onInput();
        }
      }
      break;
    }
  };

  const onClickCandidate = (code: string) => {
    const prefix = smilePrefix() || '';
    refInput.setRangeText(`${code.substring(prefix.length)}: `, refInput.selectionStart!, refInput.selectionEnd!, 'end');
    refInput.focus();
    onInput();
  };

  return (
    <div class="chat">
      <div class="messages">
        <For each={messages().toArray()}>{(message, i) =>
          <Message message={message.message} author={message.author} isLast={i() == messages().size - 1} />
        }</For>
      </div>
      <Show when={token} fallback={
        <a href={`/worker/oauth/twitch/start?${new URLSearchParams({
          url: window.location.pathname,
        })}`}>Login with Twitch</a>
      }>
        <div class="smiles">
          <For each={smileCandidates()}>{(candidate) => <Smile code={candidate} onClick={onClickCandidate} />}</For>
        </div>
        <input ref={refInput!} type="text" onKeyDown={onKeyDown} onInput={onInput} autofocus />
      </Show>
    </div>
  );
};

const App: Component = () => {
  return (
    <Router>
      <Routes routes={[
        {
          path: 'oauth',
          component: () => <Routes routes={[
            {
              path: 'twitch',
              component: () => {
                const navigate = useRoutingNavigate();

                const [tokenResource] = createResource(async () => {
                  const response = await fetch(`/worker/oauth/twitch/finish?${(new URL(window.location.href)).searchParams}`);
                  return await response.json();
                });

                createEffect(() => {
                  const token = tokenResource();
                  if(token == null) return;
                  window.localStorage.setItem('token', token);
                  navigate((new URL(window.location.href)).searchParams.get('state')!, {
                    replace: true,
                  });;
                });
                return 'twitch auth...';
              },
            },
          ]} />,
        },
        {
          path: 'twitch',
          component: () => <Routes routes={[
            {
              component: (props) => <Routes routes={[
                {
                  path: '',
                  component: () => <Stream id={`twitch/${props.route}`} player={
                    <iframe
                      src={`https://player.twitch.tv/?${new URLSearchParams({
                        channel: props.route,
                        parent: config.appDomain,
                        autoplay: "true",
                      })}`}
                      allow="autoplay; fullscreen"
                    />
                  } />,
                },
                {
                  path: 'chat',
                  component: () => <div class="popout"><Chat id={`twitch/${props.route}`} /></div>,
                },
              ]} />,
            },
          ]} />
        },
      ]} />
    </Router>
  );
};

render(() => <App />, document.body);
